import { stringify } from 'qs'
import type { ApiResponse, LeadEnrichment, PaginationResponse } from '~/types'

export const useGetLeadEnrichments = async (query: Object) => {
  const queryFormatted = stringify(query, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch<PaginationResponse>(`/lead-enrichments?${queryFormatted}`)
  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetLeadEnrichment = async (id: number) => {
  const { data, error } = await useAPIFetch(`/lead-enrichments/${id}`)
  if (error.value) throw createError(error.value)

  return data.value
}

export const useCreateLeadEnrichment = async (body: Partial<LeadEnrichment>) => {
  const { data, error } = await useAPIFetch(`/lead-enrichments`, {
    method: 'post',
    body,
  })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useUpdateLeadEnrichment = async (id: number, body: Partial<LeadEnrichment>) => {
  const { data, error } = await useAPIFetch(`/lead-enrichments/${id}`, {
    method: 'patch',
    body,
  })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useDeleteLeadEnrichment = async (id: number) => {
  const { data, error } = await useAPIFetch(`/lead-enrichments/${id}`, {
    method: 'delete',
  })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useValidateLeadEnrichment = async (body: Partial<LeadEnrichment>) => {
  const { data, error } = await useAPIFetch<ApiResponse>(`/lead-enrichments/validate`, {
    method: 'post',
    body,
  })
  if (error.value) throw createError(error.value.data as Error)

  return data.value as ApiResponse
}

export const useExportLeadEnrichmentLeads = async (id: number) => {
  return await new Promise((resolve, reject) => {
    useAPIFetch(`/lead-enrichments/${id}/leads/export`, {
      onResponse({ response }) {
        if (!response.ok) reject(createError(response.statusText))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
    })
  })
}
